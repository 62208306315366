define("discourse/plugins/automation/admin/components/fields/da-field-label", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FieldLabel = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{#if @label}}
      <label class="control-label">
        <span>
          {{@label}}
          {{#if @field.isRequired}}
            *
          {{/if}}
        </span>
      </label>
    {{/if}}
  
  */
  {
    "id": "VmKQIDvQ",
    "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,\"\\n      \"],[10,1],[12],[1,\"\\n        \"],[1,[30,1]],[1,\"\\n\"],[41,[30,2,[\"isRequired\"]],[[[1,\"          *\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"@label\",\"@field\"],false,[\"if\"]]",
    "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/automation/admin/components/fields/da-field-label.js",
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = FieldLabel;
});