define("discourse/plugins/automation/admin/components/fields/da-boolean-field", ["exports", "@ember/component", "@ember/modifier", "@ember/object", "discourse/plugins/automation/admin/components/fields/da-base-field", "discourse/plugins/automation/admin/components/fields/da-field-description", "discourse/plugins/automation/admin/components/fields/da-field-label", "@ember/template-factory"], function (_exports, _component, _modifier, _object, _daBaseField, _daFieldDescription, _daFieldLabel, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BooleanField extends _daBaseField.default {
    static #_ = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <section class="field boolean-field">
          <div class="control-group">
            <DAFieldLabel @label={{@label}} @field={{@field}} />
    
            <div class="controls">
              <Input
                @type="checkbox"
                @checked={{@field.metadata.value}}
                {{on "input" this.onInput}}
                disabled={{@field.isDisabled}}
              />
    
              <DAFieldDescription @description={{@description}} />
            </div>
          </div>
        </section>
      
    */
    {
      "id": "b4rV16Hw",
      "block": "[[[1,\"\\n    \"],[10,\"section\"],[14,0,\"field boolean-field\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@label\",\"@field\"],[[30,1],[30,2]]],null],[1,\"\\n\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n          \"],[8,[32,1],[[16,\"disabled\",[30,2,[\"isDisabled\"]]],[4,[32,2],[\"input\",[30,0,[\"onInput\"]]],null]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,2,[\"metadata\",\"value\"]]]],null],[1,\"\\n\\n          \"],[8,[32,3],null,[[\"@description\"],[[30,3]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@label\",\"@field\",\"@description\"],false,[]]",
      "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/automation/admin/components/fields/da-boolean-field.js",
      "scope": () => [_daFieldLabel.default, _component.Input, _modifier.on, _daFieldDescription.default],
      "isStrictMode": true
    }), this))();
    onInput(event1) {
      this.mutValue(event1.target.checked);
    }
    static #_2 = (() => dt7948.n(this.prototype, "onInput", [_object.action]))();
  }
  _exports.default = BooleanField;
});